import React, { PropsWithChildren } from 'react';
import Header from 'src/components/Header';

export default function BasicLayout(props: PropsWithChildren<{}>): JSX.Element {
  return (
    <>
      <Header />
      <div className="mb-16">{props.children}</div>
    </>
  );
}
