import { Link } from 'gatsby';
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/pro-solid-svg-icons';
import logo from 'src/images/gmscreenlogo.png';

export default function Header(): JSX.Element {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <header className="border-b-2 border-purple-500 mb-4">
      <div className="mx-auto sm:flex sm:justify-between sm:px-4 sm:py-3 sm:items-center max-w-screen-xl">
        <div className="flex items-center justify-between px-4 py-2 sm:p-0">
          <Link to="/">
            <img className="h-12" src={logo} alt="GM Screen logo" />
          </Link>
          <div className="sm:hidden">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="block focus:outline-none"
            >
              <FontAwesomeIcon
                className="text-gray-700"
                icon={isOpen ? faTimes : faBars}
              />
            </button>
          </div>
        </div>

        <div
          className={
            (isOpen ? 'block' : 'hidden') + ' px-2 pt-2 pb-4 sm:flex sm:p-0'
          }
        >
          <Link
            className="block font-medium rounded px-2 py-1 text-gray-600 hover:text-gray-700"
            activeClassName="text-teal-600"
            partiallyActive
            to="/articles"
          >
            Articles
          </Link>
          <Link
            className="block mt-1 font-medium rounded px-2 py-1 text-gray-600 hover:text-gray-700 sm:mt-0 sm:ml-1"
            activeClassName="text-teal-600"
            partiallyActive
            to="/adventures"
          >
            Adventures
          </Link>
          <Link
            className="block mt-1 font-medium rounded px-2 py-1 text-gray-600 hover:text-gray-700 sm:mt-0 sm:ml-1"
            activeClassName="text-teal-600"
            partiallyActive
            to="/tools"
          >
            Tools
          </Link>
          <Link
            className="block mt-1 font-medium rounded px-2 py-1 text-gray-600 hover:text-gray-700 sm:mt-0 sm:ml-1"
            activeClassName="text-teal-600"
            partiallyActive
            to="/games"
          >
            Games
          </Link>
        </div>
      </div>
    </header>
  );
}
